<template>
  <ul class="flex flex-col space-y-2">
    <template v-if="evaluateActions && evaluateActions.length">
      <li class="text-black text-xl font-semibold p-2 mt-2">
        Personalentwicklungsmassnahmen beurteilen
      </li>
      <li
        class="flex flex-col w-full rounded border card"
        v-for="(row, index) in oldActions"
        :key="`development-action-${index}`"
      >
        <a
          href=""
          @click.prevent="
            $emit(
              'current',
              current === `${$options.name}-evaluate-${index}`
                ? null
                : `${$options.name}-evaluate-${index}`
            )
          "
          class="bg-gray-100 pl-3 pr-3 pt-3 pb-3 text-sm font-semibold card-header flex justify-start items-center space-x-16"
        >
          <div>
            Personalentwicklingsmassnahme
            {{ row.type === 'intern' && 'beurteilen' }}:
            {{ row.title }}
          </div>
          <div>Typ: {{ row.type }}</div>
        </a>
        <div
          v-if="current === `${$options.name}-evaluate-${index}`"
          class="flex flex-col w-full p-2 space-y-4 relative"
        >
          <p>{{ row.description }}</p>

          <div class="flex flex-col w-1/6 space-y-4">
            <div class="flex flex-col space-y-2">
              <label class="font-bold">Status</label>
              <select
                v-model="row.status"
                class="border rounded px-2 py-1 w-full"
              >
                <option value="">Select Typ</option>
                <option value="open">Offen</option>
                <option value="cancel">Abbrechen</option>
                <option value="done">Abgeschlossen</option>
              </select>
            </div>

            <div class="flex flex-col space-y-2">
              <label class="font-bold flex justify-between relative"
                >Wirksamkeit der Massnahme
                <i
                  class="fa fa-info-circle"
                  @click.prevent="showTip = !showTip"
                >
                </i>
              </label>
              <select
                v-model="row.effectivity"
                class="border rounded px-2 py-1 w-full"
              >
                <option value="">Select Typ</option>
                <option value="sofort wirksam">sofort wirksam</option>
                <option value="innert 1-6 Monaten">innert 1-6 Monaten</option>
                <option value="innert 6-12 Monaten">innert 6-12 Monaten</option>
                <option value="innert 1-3 Jahren">innert 1-3 Jahren</option>
                <option value="in über 3 Jahren">in über 3 Jahren</option>
                <option value="gar nicht: die Massnahme war nicht wirksam"
                  >gar nicht: die Massnahme war nicht wirksam</option
                >
              </select>
            </div>
          </div>

          <div
            v-show="showTip"
            class="flex flex-col absolute top-0 left-60 rounded overflow-hidden shadow"
          >
            <div class="flex justify-between p-2 bg-blue-dark">
              <p class=" text-white font-bold">Hinweis:</p>
              <a href="#" @click.prevent="showTip = false" class="text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>
            </div>
            <p class="p-2 bg-white">
              Wirksamkeit der Massnahme: Wie lange hat es gedauert (resp. wird
              es dauern),<br />
              bis sich die Investitionen (Kosten und investierte Arbeitszeit) in
              diese Massnahme ausbezshlt haben<br />
              (z.B. mittels Qualitäts- oder Effizienzsteigerung, etc.)?
            </p>
          </div>
        </div>
      </li>
    </template>
  </ul>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'review-define-development-actions',
  props: ['rows', 'current', 'isAssesment', 'evaluateActions'],
  data() {
    return {
      data: [],
      showTip: false,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const to_evaluate_actions = _.filter(this.evaluateActions, function(object){
        return object.new === false;
      });

      this.data = [...to_evaluate_actions]
    },
  },
  computed: {
    oldActions: function() {
      return _.filter(this.data, data => !data.new)
    },
  },
}
</script>

<style></style>
